import { useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import type React from 'react'
import { useEffect, useState } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'
import DefaultModal from '../../../../../../components/DefaultModal'
import FormInput from '../../../../../../components/FormInput'
import FormSelect from '../../../../../../components/FormSelect'
import Toast from '../../../../../../components/Toast'
import {
  useGetCountriesQuery,
  useUpdateUserMutation,
} from '../../../../../../redux/services/coreApi'
import { UserDto } from '../../../../../../types/coreApi-types'
import { fullNameRegex } from '../../../../../Login/utils/fullNameValidator'

type UpdateUserDetailsFormData = {
  name: string
  vatNumber?: string
  address?: string
  phone?: string
  country?: string
}

interface UpdateUserDetailsModalProps {
  isOpen: boolean
  onClose: () => void
  user?: UserDto
}

const UpdateUserDetailsModal: React.FC<UpdateUserDetailsModalProps> = ({
  isOpen,
  onClose,
  user,
}) => {
  const toast = useToast()
  const [updateUser, { isLoading }] = useUpdateUserMutation()
  const [error, setError] = useState<string | null>(null)
  const countries = useGetCountriesQuery()

  const schema: yup.SchemaOf<UpdateUserDetailsFormData> = yup.object({
    name: yup
      .string()
      .required('This field is required.')
      .matches(fullNameRegex, 'Please enter at least your first and last name'),
    vatNumber: yup.string().when([], {
      is: () => user?.role === 'Organization',
      then: yup.string().required('This field is required'),
    }),
    address: yup.string(),
    phone: yup.string(),
    country: yup.string(),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    watch,
  } = useForm<UpdateUserDetailsFormData>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  })

  const resetModal = () => {
    setValue('name', '')
    setValue('vatNumber', '')
    setValue('address', '')
    setValue('phone', '')
    setValue('country', '')
    clearErrors()
    setError(null)
    onClose()
  }

  useEffect(() => {
    if (user && isOpen) {
      setValue('name', user.name)
      setValue('address', user.address)
      setValue('phone', user.phone)
      setValue('country', user.country.name)

      if (user.vatNumber) {
        setValue('vatNumber', user.vatNumber)
      }
    }
  }, [user, isOpen])

  const onSubmit: SubmitHandler<UpdateUserDetailsFormData> = async ({
    name,
    vatNumber,
    address,
    phone,
    country,
  }) => {
    setError(null)
    const isoCode = countries.data?.find(el => el.name === country)?.isoCode
    if (!isoCode) return

    if (!user) return

    try {
      const res = await updateUser({
        userId: user.id,
        name,
        vatNumber,
        address,
        phone,
        countryIsoCode: isoCode,
      }).unwrap()

      toast({
        position: 'bottom-right',
        render: () => (
          <Toast type="success">Succesfully updated user details</Toast>
        ),
      })
    } catch (err) {
      let errMsg = (err as any)?.data?.errors
      if (!errMsg)
        errMsg = 'Something went wrong trying to update user details.'

      toast({
        position: 'bottom-right',
        render: () => <Toast type="error">{errMsg}</Toast>,
      })
      //@ts-ignore
      setError('Something went wrong. Please contact support.')
      return
    }

    resetModal()
  }

  return (
    <DefaultModal
      isLoading={isLoading}
      isOpen={isOpen}
      onClose={() => {
        resetModal()
      }}
      title={'Update User details'}
      isLoadingText="Updating user details..."
      whiteBG
    >
      <form onSubmit={handleSubmit(onSubmit)} className="">
        <div className="p-5 pb-0 space-y-3">
          <FormInput
            label="Full name"
            inputMode="text"
            registeredForm={register('name')}
            error={errors?.name}
          />

          {user?.role === 'Organization' && (
            <FormInput
              label="Vat Number"
              inputMode="text"
              registeredForm={register('vatNumber')}
              error={errors?.vatNumber}
            />
          )}

          <FormSelect
            label="Country"
            options={countries.data?.map(item => item.name) || []}
            registeredForm={register('country')}
            placeholder="Select a country"
          />

          <FormInput
            label="Address"
            inputMode="text"
            registeredForm={register('address')}
            error={errors?.address}
          />

          <FormInput
            label="Phone nr."
            inputMode="text"
            registeredForm={register('phone')}
            error={errors?.phone}
          />
        </div>

        <div className="p-5 pt-0">
          <button type="submit" className="button mt-10">
            Update
          </button>
        </div>
      </form>
    </DefaultModal>
  )
}

export default UpdateUserDetailsModal
