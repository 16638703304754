import { faCopy } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type React from 'react'
import InformationCircleIcon from '../../../../../assets/icons/information-circle-stroke-rounded'
import AnimateHeight from '../../../../../components/AnimateHeight'
import CopyTextWrapper from '../../../../../components/CopyTextWrapper'
import type {
  BankAccountDetailsDto,
  BankAccountDto,
  CurrencyDto,
} from '../../../../../types/coreApi-types'
import BankInfoCell from './BankInfoCell'

interface BankInfoContainerProps {
  depositUniqueId?: string
  transferAmount?: string
  accountInfo?: BankAccountDto
  depositCurrency?: CurrencyDto
}

const DomesticBankInfoCells: React.FC<{
  bankAccountDetails?: BankAccountDetailsDto
}> = ({ bankAccountDetails }) => {
  if (bankAccountDetails) {
    return (
      <>
        <BankInfoCell
          title="Registration number"
          value={bankAccountDetails?.registration}
          copy
        />
        <BankInfoCell
          title="Account number"
          value={bankAccountDetails?.accountNumber}
          copy
        />
      </>
    )
  }
  return null
}

const InternationalBankInfoCells: React.FC<{
  bankAccountDetails?: BankAccountDetailsDto
}> = ({ bankAccountDetails }) => {
  if (bankAccountDetails) {
    return (
      <>
        <BankInfoCell title="IBAN" value={bankAccountDetails?.iban} copy />
        <BankInfoCell
          title="BIC (SWIFT)"
          value={bankAccountDetails?.bic}
          copy
        />
        <BankInfoCell
          title="Recipient name"
          value={bankAccountDetails?.recipientName}
          copy
        />
        <BankInfoCell
          title="Recipient address"
          value={bankAccountDetails?.recipientAddress}
          copy
        />
        <BankInfoCell
          title="Recipient postal/zip code"
          value={bankAccountDetails?.recipientZipCode}
          copy
        />
        <BankInfoCell
          title="Recipient city"
          value={bankAccountDetails?.recipientCity}
          copy
        />
        <BankInfoCell
          title="Recipient country"
          value={bankAccountDetails?.recipientCountry}
          copy
        />
      </>
    )
  }
  return null
}

const BankInfoContainer: React.FC<BankInfoContainerProps> = ({
  depositUniqueId,
  transferAmount,
  accountInfo,
  depositCurrency,
}) => {
  // const [showIdWarning, setShowIdWarning] = useState<boolean>(false)

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setShowIdWarning(true)
  //   }, 1000)

  //   return () => {
  //     clearTimeout(timeout)
  //   }
  // }, [setShowIdWarning])

  return (
    <div className="p-5">
      <div className="flex gap-5 flex-wrap">
        {transferAmount && (
          <BankInfoCell title="Amount" value={transferAmount} copy />
        )}
        {accountInfo?.bankAccountDetails?.iban ? (
          <InternationalBankInfoCells
            bankAccountDetails={accountInfo?.bankAccountDetails}
          />
        ) : (
          <DomesticBankInfoCells
            bankAccountDetails={accountInfo?.bankAccountDetails}
          />
        )}
      </div>

      {depositUniqueId && (
        <div className="pt-6">
          <div className="flex items-center gap-2">
            <p className="text-description">Description</p>
          </div>
          <CopyTextWrapper text={depositUniqueId || ''}>
            <div className="mt-2.5 bg-[#E1E5EA] dark:bg-brand-dark-card text-default py-2.5 px-4 rounded-md flex items-center justify-between cursor-pointer">
              {depositUniqueId}
              <span className="text-brand-accent dark:text-white ml-4">
                <FontAwesomeIcon icon={faCopy} size="lg" />
              </span>
            </div>
          </CopyTextWrapper>
          <AnimateHeight
            isVisible={
              depositCurrency?.isoCode === 'DKK' ||
              depositCurrency?.isoCode === 'EUR'
            }
            delayInMS={1000}
          >
            <div className="border border-black/10 rounded-xl border-solid mt-5 p-4">
              <div className="flex items-center gap-2">
                <InformationCircleIcon className="w-5 h-5 text-red-600" />
                <p className="font-semi text-red-600 text-sm">
                  Important information
                </p>
              </div>

              <ul className="mt-3 font-medium text-sm list-disc ml-5 gap-3 flex flex-col">
                {depositCurrency?.isoCode === 'DKK' && (
                  <>
                    <li>
                      Include the code above in the description / text of your
                      bank transfer (
                      <b className="font-semi">{depositUniqueId}</b>
                      ). This is used to verify your transaction.
                    </li>

                    <li>
                      Our current banking partners unfortunately do not support
                      DKK "Straksoverførsel". We recommend you submit your order
                      in EUR and use <b className="font-semi">SEPA Instant</b> ,
                      to avoid market slippage. Any DKK "Straksoverførsel" will
                      be rejected by our banking partners and bounce back to
                      your account.{' '}
                    </li>

                    <li>
                      If you still prefer to make your purchase in DKK, use {' '}
                      <b className="font-semi">
                        DKK standard transaction only, NOT “Straksoverførsel”
                      </b>
                      . Expected transaction time: 1-2 working days.
                    </li>

                    <li>
                      In cases where the market price of the asset has changed
                      to a degree where Penning will incur a loss, due to the
                      time it takes for the payment to be received on our
                      account, Penning will execute the order at the best
                      possible market price at the time of payment receipt, but
                      will reserve the right to change the asset amount on the
                      order in such cases, to avoid a loss.
                    </li>
                  </>
                )}

                {depositCurrency?.isoCode === 'EUR' && (
                  <>
                    <li>
                      Include the code above in the description / text of your
                      bank transfer (
                      <b className="font-semi">{depositUniqueId}</b>
                      ). This is used to verify your transaction.
                    </li>
                    <li>
                      For orders where payment is in EUR, we highly recommend
                      that you use <b className="font-semi">SEPA Instant</b> ,
                      in order to ensure a timely execution of your order, and
                      thus avoid potential slippage in the market price.
                      Expected transaction times: SEPA Instant: Same day | SEPA
                      Credit: 1-3 working days.
                    </li>
                    <li>
                      In cases where the market price of the asset has changed
                      to a degree where Penning will incur a loss, due to the
                      time it takes for the payment to be received on our
                      account, Penning will execute the order at the best
                      possible market price at the time of payment receipt, but
                      will reserve the right to change the asset amount on the
                      order in such cases, to avoid a loss.
                    </li>
                  </>
                )}
              </ul>
            </div>
          </AnimateHeight>
        </div>
      )}
    </div>
  )
}

export default BankInfoContainer
