import { useToast } from '@chakra-ui/react'
import { faClock, faShield } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type React from 'react'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import AnimateHeight from '../../../../../components/AnimateHeight'
import DefaultModal from '../../../../../components/DefaultModal'
import FormCheckbox from '../../../../../components/FormCheckbox'
import MessageBox from '../../../../../components/MessageBox'
import Toast from '../../../../../components/Toast'
import {
  useCreateSwapMutation,
  useGetBankAccountsQuery,
} from '../../../../../redux/services/coreApi'
import type { RootState } from '../../../../../redux/store'
import type {
  BankAccountDto,
  CurrencyDto,
  NetworkDto,
  SwapDto,
  WalletDto,
} from '../../../../../types/coreApi-types'
import SwapSummaryContainer from '../components/SwapSummaryContainer'

interface Props {
  isOpen: boolean
  onClose: () => void
  payAmount: number
  receiveAmount: number
  payCurrency: CurrencyDto
  receiveCurrency: CurrencyDto
  selectedWallet: WalletDto | null
  selectedBankAccount: BankAccountDto | null
  selectedNetwork: NetworkDto | null
  onSuccess: (swap?: SwapDto) => void
  lastEditedAmountInput: 'in' | 'out'
  baseFeeRate: number
  bankFeeRate: number
}

const SwapSummaryModal: React.FC<Props> = ({
  isOpen,
  onClose,
  payAmount,
  receiveAmount,
  payCurrency,
  receiveCurrency,
  selectedWallet,
  selectedBankAccount,
  selectedNetwork,
  onSuccess,
  lastEditedAmountInput,
  baseFeeRate,
  bankFeeRate,
}) => {
  const toast = useToast()
  const [createSwap, { isLoading }] = useCreateSwapMutation()
  const [confirmChecked, setConfirmChecked] = useState<boolean>(false)
  const [confirmError, setConfirmError] = useState<string>('')
  const userInfo = useSelector((state: RootState) => state.session.user)

  useEffect(() => {
    if (!isOpen) {
      setConfirmChecked(false)
      setConfirmError('')
    }
  }, [setConfirmChecked, setConfirmError, isOpen])

  const isWalletTransfer = receiveCurrency?.currencyType === 'CryptoCurrency'
  const isBankTransfer = receiveCurrency?.currencyType === 'FiatCurrency'

  const { data: bankAccounts } = useGetBankAccountsQuery(null)

  const userHasCreatedBankAccount = bankAccounts && bankAccounts.length > 0

  return (
    <DefaultModal
      isLoading={isLoading}
      isLoadingText="Generating swap..."
      isOpen={isOpen}
      onClose={onClose}
      title="Swap summary"
      maxW="700px"
    >
      <div className="p-5">
        <SwapSummaryContainer
          payAmount={payAmount}
          payCurrency={payCurrency}
          receiveAmount={receiveAmount}
          receiveCurrency={receiveCurrency}
          baseFeeRate={baseFeeRate}
          bankFeeRate={bankFeeRate}
        />

        {isWalletTransfer && (
          <div className="mt-8 bg-brand-light-card dark:bg-brand-dark-card grid grid-cols-10 justify-between p-5 rounded-md">
            <div className="col-span-6">
              <p className="text-default flex items-center gap-2">
                <img
                  src={selectedWallet?.network?.iconUrl}
                  className="inline-icon"
                />
                {selectedWallet?.address}
              </p>
              <p className="text-description mt-1">Address</p>
              {selectedWallet?.destinationTag && (
                <>
                  <p className="text-default flex items-center gap-2 mt-1">
                    <img
                      src={selectedWallet?.network?.iconUrl}
                      className="inline-icon"
                    />
                    {selectedWallet?.destinationTag}
                  </p>
                  <p className="text-description mt-1">Destination tag</p>
                </>
              )}
            </div>
            <div className="col-span-4">
              <div className="flex items-center gap-2">
                <img
                  src={selectedWallet?.network?.iconUrl}
                  className="inline-icon"
                />
                <p className="text-default">{selectedWallet?.network?.name}</p>
              </div>

              <p className="text-description mt-1">Network</p>
            </div>
          </div>
        )}
        {isBankTransfer && (
          <div className="mt-8 bg-brand-light-card dark:bg-brand-dark-card grid grid-cols-10 justify-between p-5 rounded-md">
            <div className="col-span-6">
              <p className="text-default flex items-center gap-2">
                {selectedBankAccount?.name}
              </p>
              <p className="text-description mt-1">
                {selectedBankAccount?.description}
              </p>
            </div>
            <div className="col-span-4">
              <div className="flex items-center gap-2">
                <p className="text-default">
                  {selectedBankAccount?.bankAccountDetails.accountNumber ||
                    selectedBankAccount?.bankAccountDetails.iban}
                </p>
              </div>

              <p className="text-description mt-1">
                {selectedBankAccount?.bankAccountDetails.accountNumber
                  ? 'Account number'
                  : selectedBankAccount?.bankAccountDetails.iban
                    ? 'IBAN'
                    : ''}
              </p>
            </div>
          </div>
        )}

        <div className="my-10">
          <div className="flex mb-5">
            <div className="w-[36px] text-brand-accent flex-shrink-0">
              <FontAwesomeIcon icon={faClock} />
            </div>
            <div>
              <p className="text-default mb-1">Processing time</p>

              <p className="text-description">
                Funds will be credited to your{' '}
                {isWalletTransfer ? 'wallet' : 'bank account'} as soon as we
                receive your deposit. Transfers usually take around 1-3 business
                days to process depending on the bank and/or the network used.
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="w-[36px] text-brand-accent flex-shrink-0">
              <FontAwesomeIcon icon={faShield} />
            </div>
            <div>
              <p className="text-default mb-1">Correct transfer details</p>
              {isWalletTransfer && (
                <p className="text-description">
                  Ensure that your wallet address is correct and on the
                  appropriate network. After we have sent you the funds, it
                  cannot be reverted.
                </p>
              )}
              {isBankTransfer && (
                <p className="text-description">
                  Ensure that the bank account information above is correct.
                  After we have sent you the funds, it cannot be reverted.
                </p>
              )}
            </div>
          </div>
        </div>

        {userHasCreatedBankAccount ? (
          <FormCheckbox
            renderLabel={`I confirm that I have selected the correct ${
              isWalletTransfer
                ? 'wallet address and the appropriate network'
                : 'bank account'
            } for the transaction.`}
            onChange={() => {
              if (confirmError && !confirmChecked) {
                setConfirmError('')
              }
              setConfirmChecked(!confirmChecked)
            }}
            checked={confirmChecked}
            error={{ message: confirmError }}
          />
        ) : (
          <AnimateHeight isVisible={true} delayInMS={100}>
            <MessageBox
              type="warning"
              title="NO BANK ACCOUNT"
              description="You must create and link a bank account to your account before you can create a swap. You can do so in the 'Bank Accounts' section, by entering your bank account details."
            />
          </AnimateHeight>
        )}

        <button
          className="button mt-10"
          disabled={
            userInfo.kycDetails.kycStatus !== 'Approved' ||
            !confirmChecked ||
            !userHasCreatedBankAccount
          }
          onClick={async () => {
            if (!confirmChecked) {
              setConfirmError('Required')
              return
            }

            let amountIn, amountOut
            if (lastEditedAmountInput === 'in') {
              amountIn = payAmount
              amountOut = null
            } else {
              amountIn = null
              amountOut = receiveAmount
            }

            const res = await createSwap({
              amountIn,
              amountOut,
              currencyInId: payCurrency?.id || '',
              currencyOutId: receiveCurrency?.id || '',
              ...(isWalletTransfer
                ? {
                    walletOutId: selectedWallet?.id,
                  }
                : isBankTransfer
                  ? {
                      bankAccountOutId: selectedBankAccount?.id,
                    }
                  : {}),
              ...(payCurrency?.currencyType === 'CryptoCurrency'
                ? {
                    currencyInNetworkId: selectedNetwork?.id,
                  }
                : {}),
            }).unwrap()

            onClose()

            onSuccess(res)
            toast({
              position: 'top-right',
              render: () => {
                return <Toast type="success">Swap successfully created</Toast>
              },
            })
          }}
        >
          Generate swap
        </button>
      </div>
    </DefaultModal>
  )
}

export default SwapSummaryModal
